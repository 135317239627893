import React, { useState, useEffect, useRef } from "react";
import Static from "../../../assets/icons/static.gif";
import "./naytv.css";

const videoData = [
  {
    title: "why-ux.avi",
    videoId: "5VNLXjs4hZo",
    muted: false,
  },
  {
    title: "wonderland.avi",
    videoId: "H92eqGwguTM",
    muted: false,
  },
  {
    title: "lets-game.mp4",
    videoId: "NRm9EB9adRU",
    muted: false,
  },
  {
    title: "tinker-bell-life.avi",
    videoId: "f0y76gV2LL4",
    muted: true,
  },
  {
    title: "out-run.mp4",
    videoId: "hMv0Pg1AxFc",
    muted: true,
  },
];

const NayTV = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const iframeRef = useRef(null);

  const handleNextChannel = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoData.length);
  };

  const handlePrevChannel = () => {
    setCurrentVideoIndex(
      (prevIndex) => (prevIndex - 1 + videoData.length) % videoData.length
    );
  };

  useEffect(() => {
    const iframe = iframeRef.current;

    const handleLoad = () => {
      setShowLoader(false);
    };

    if (iframe) {
      iframe.addEventListener("load", handleLoad);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", handleLoad);
      }
    };
  }, []);

  const getIframeSrc = () => {
    const { videoId, muted } = videoData[currentVideoIndex];
    const muteParam = muted ? "&mute=1" : "";
    return `https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&modestbranding=1&rel=0&iv_load_policy=3&loop=1${muteParam}`;
  };

  return (
    <div className="naytv">
      <div className="video-container">
        {showLoader && (
          <div className="video-loader">
            <img src={Static} alt="Loading..." />
          </div>
        )}
        <iframe
          ref={iframeRef}
          id="youtube-player"
          className={`youtube-player ${showLoader ? "hidden" : ""}`}
          src={getIframeSrc()}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title={videoData[currentVideoIndex].title}
        ></iframe>
        <div className="pixel-overlay"></div>
      </div>

      <div className="bottom-bar">
        <div className="bottom-bar-left">
          <div className="bottom-bar-left-item" onClick={handlePrevChannel}>
            Prev Channel
          </div>
        </div>
        <div className="bottom-bar-center">
          <div className="bottom-bar-center-item">
            {videoData[currentVideoIndex].title}
          </div>
        </div>
        <div className="bottom-bar-right">
          <div className="bottom-bar-right-item" onClick={handleNextChannel}>
            Next Channel
          </div>
        </div>
      </div>
    </div>
  );
};

export default NayTV;
