import React from "react";
import "./bottomnav.css";
import AboutMeIcon from "../../assets/icons/about-me-icon.png";
import MyWorksIcon from "../../assets/icons/my-works-icon.png";
import CalendarIcon from "../../assets/icons/my-calendar-icon.png";
// import InstagramIcon from "../../assets/icons/instagram-icon.png";
import NayTVIcon from "../../assets/icons/nay-tv-icon.png";
import ResumeIcon from "../../assets/icons/resume-icon.png";
import NavItem from "./NavItem";

const BottomNav = ({ onClick }) => {
  const navItems = [
    {
      id: 1,
      iconSrc: AboutMeIcon,
      altText: "About Me",
      label: "About Me",
      windowId: "aboutMe",
    },
    {
      id: 2,
      iconSrc: MyWorksIcon,
      altText: "My Works",
      label: "My Works",
      windowId: "myWorks",
    },
    {
      id: 3,
      iconSrc: CalendarIcon,
      altText: "Calendar",
      label: "Calendar",
      windowId: "calendar",
    },
    {
      id: 4,
      iconSrc: ResumeIcon,
      altText: "Resume",
      label: "Resume",
      windowId: "resume",
    },
    // {
    //   id: 5,
    //   iconSrc: InstagramIcon,
    //   altText: "Instagram",
    //   label: "Instagram",
    //   windowId: "instagram",
    // },
    {
      id: 6,
      iconSrc: NayTVIcon,
      altText: "Nay TV",
      label: "Nay TV",
      windowId: "nayTV",
    },
  ];

  return (
    <div className="bottom-nav">
      {navItems.map(({ id, iconSrc, altText, label, windowId }) => (
        <NavItem
          key={id}
          iconSrc={iconSrc}
          altText={altText}
          label={label}
          onClick={() => onClick(windowId)}
        />
      ))}
    </div>
  );
};

export default BottomNav;
