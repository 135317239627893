import React from "react";
import "./windowcommon.css";
import PropTypes from "prop-types";

const WindowBody = ({ size, children }) => {
  return (
    <div className="window-body" style={{ ...size }}>
      {children}
    </div>
  );
};

WindowBody.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
};

export default WindowBody;
