import React, { useState, useEffect, useRef } from "react";
import "./bootpage.css";
import bootMessages from "./bootMessages";

const BootPage = ({ onBootComplete }) => {
  const [visibleLines, setVisibleLines] = useState([]);
  const [loadingSpeed, setLoadingSpeed] = useState(70); // Initial speed
  const [isFontLoaded, setIsFontLoaded] = useState(false); // New state for font loading
  const bootPageRef = useRef(null);

  // Function to randomly assign colored text to a line
  const getRandomColor = () => {
    const shouldColorChange = Math.random() < 0.2; // 20% chance for a different color
    if (shouldColorChange) {
      return "#65BFD7"; // Special color
    }
    return ""; // Default color
  };

  useEffect(() => {
    // Simulate font loading delay
    const fontLoadingDelay = setTimeout(() => {
      setIsFontLoaded(true);
    }, 2000); // 3-second delay before messages start appearing

    return () => clearTimeout(fontLoadingDelay);
  }, []);

  useEffect(() => {
    if (!isFontLoaded) return;

    // Adjust the speed based on line count
    setLoadingSpeed(Math.max(40, 70 - visibleLines.length * 2));

    // Load the next line after the calculated interval
    const timeoutId = setTimeout(() => {
      if (visibleLines.length < bootMessages.length) {
        setVisibleLines((currentVisibleLines) => [
          ...currentVisibleLines,
          {
            text: bootMessages[currentVisibleLines.length],
            color: getRandomColor(),
          },
        ]);
      } else {
        clearTimeout(timeoutId);
        setTimeout(onBootComplete, 1000);
      }
    }, loadingSpeed);

    return () => clearTimeout(timeoutId);
  }, [visibleLines.length, onBootComplete, loadingSpeed, isFontLoaded]);

  // Scroll to the bottom of the page when a new line is added
  useEffect(() => {
    if (bootPageRef.current) {
      const scrollHeight = bootPageRef.current.scrollHeight;
      bootPageRef.current.scrollTop = scrollHeight;
    }
  }, [visibleLines]);

  return (
    <div className="boot-page" ref={bootPageRef}>
      {!isFontLoaded ? (
        <div className="blinking-cursor" />
      ) : (
        visibleLines.map((line, index) => (
          <p className="boot-message" key={index} style={{ color: line.color }}>
            {line.text}
          </p>
        ))
      )}
      {isFontLoaded && <div className="cursor" />}
    </div>
  );
};

export default BootPage;
