import React, { useEffect } from "react";
import "./projectscommon.css";
import LazyImage from "../../../LazyImage";

import Overview from "../../../../assets/MedFolio/Overview.jpg";
import Moodboard from "../../../../assets/MedFolio/Moodboard.jpg";
import HiFiDesign from "../../../../assets/MedFolio/HiFiDesign.jpg";

const Tabs = ["Overview", "Moodboard", "Hi-Fi Design"];

const MedFolio = ({ setProjectTabs }) => {
  useEffect(() => {
  
    setProjectTabs(Tabs);

  
    return () => setProjectTabs([]);
  }, [setProjectTabs]);

  return (
    <div className="project-body">
      <div id="Overview">
        <LazyImage src={Overview} alt="Overview" />
      </div>
      <div id="Moodboard">
        <LazyImage src={Moodboard} alt="Moodboard" />
      </div>
      <div id="Hi-Fi Design">
        <LazyImage src={HiFiDesign} alt="HiFiDesign" />
        <iframe
          title="GoodReads Medium Fidelity Prototype"
          style={{
            width: "100%",
            height: "600px",
            border: "none",
            filter: "none",
            padding: "3%",
          }}
          src="https://www.figma.com/embed?embed_host=share&hide_ui=1&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FlZzx38JLnom7gNZncdmlQu%2FA3_Nayanika%3Fpage-id%3D1%253A14%26node-id%3D1-15%26viewport%3D157%252C241%252C0.18%26t%3DItMYoSgqsPzrRGma-1%26scaling%3Dcontain%26content-scaling%3Dfixed%26starting-point-node-id%3D1%253A15%26show-proto-sidebar%3D1"
        ></iframe>
      </div>
    </div>
  );
};

export default MedFolio;
