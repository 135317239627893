import React from "react";
import "./myworkscard.css";
import useLazyLoadingImage from "../../../hooks/useLazyLoadingImg";

const MyWorksCard = ({ image, title, onClick }) => {
  // Add an argument 'event' to the onClick handler
  const handleClick = (event) => {
    // Stop the event from propagating up to parent elements
    event.stopPropagation();
    onClick();
  };

  const { imageSrc, className } = useLazyLoadingImage(image);

  return (
    <div className="project-card" onClick={handleClick}>
      <img
        src={imageSrc}
        alt={title}
        className={`project-image lazy-loading-img ${className}`}
      />
      <div className="project-title">{title}</div>
    </div>
  );
};

export default MyWorksCard;
