import React from "react";
import "./projectscommon.css";

const TabBar = ({ activeTab, onTabClick, tabs, hideTabs }) => {
  return (
    <div className={`tab-bar ${hideTabs ? "hidden" : ""}`}>
      {tabs.map((tab) => (
        <div
          key={tab}
          className={`tab-item ${activeTab === tab ? "active" : ""}`}
          onClick={(e) => onTabClick(tab, e)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default TabBar;
