import React, { useState, useEffect, useRef } from "react";
import "./musicplayer.css";
import LazyImage from "../LazyImage";
// songs
import Sky from "../../assets/songs/songs/PlayboiCartiSky.mp3";
import Redbone from "../../assets/songs/songs/ChildishGambinoRedbone.mp3";
import Boulevard from "../../assets/songs/songs/GreenDayBoulevardOfBrokenDreams.mp3";
import HotelCalifornia from "../../assets/songs/songs/HotelCalifornia.mp3";
import KissMe from "../../assets/songs/songs/KissMe.mp3";
import StayinAlive from "../../assets/songs/songs/StayinAlive.mp3";
import OneLove from "../../assets/songs/songs/Blue_OneLove.mp3";
import Coolio from "../../assets/songs/songs/Coolio_GangstasParadise.mp3";
import Timezone from "../../assets/songs/songs/Timezone.mp3";
import グッバイバイ from "../../assets/songs/songs/Hatsune.mp3";

// Album Art
import BoulevardCover from "../../assets/songs/AlbumArt/Green_Day_Boulevard_of_Broken_Dreams_cover.jpg";
import RedboneCover from "../../assets/songs/AlbumArt/Redbone_cover.png";
import KissMeCover from "../../assets/songs/AlbumArt/KissMe_cover.jpg";
import StayinAliveCover from "../../assets/songs/AlbumArt/StayinAlive_cover.jpg";
import HotelCaliforniaCover from "../../assets/songs/AlbumArt/HotelCalifornia_cover.jpg";
import OneLoveCover from "../../assets/songs/AlbumArt/OneLove_cover.jpg";
import CoolioCover from "../../assets/songs/AlbumArt/Coolio_GangstasParadise_cover.jpg";
import TimezoneCover from "../../assets/songs/AlbumArt/Timezone_cover.jpg";
import グッバイバイCover from "../../assets/songs/AlbumArt/Hatsune_cover.jpg";
import SkyCover from "../../assets/songs/AlbumArt/PlayboiCartiSky_cover.jpg";

import playicon from "../../assets/icons/play-icon.png";
import pauseicon from "../../assets/icons/pause-icon.png";
import nexticon from "../../assets/icons/next-icon.png";

const songs = [
  {
    title: "Hotel California",
    artist: "Eagles",
    src: HotelCalifornia,
    albumArt: HotelCaliforniaCover,
  },
  {
    title: "Boulevard of Broken Dreams",
    artist: "Green Day",
    src: Boulevard,
    albumArt: BoulevardCover,
  },
  {
    title: "Kiss Me",
    artist: "Sixpence None The Richer",
    src: KissMe,
    albumArt: KissMeCover,
  },
  {
    title: "Stayin' Alive",
    artist: "Bee Gees",
    src: StayinAlive,
    albumArt: StayinAliveCover,
  },

  { title: "Sky", artist: "Playboi Carti", src: Sky, albumArt: SkyCover },
  {
    title: "Redbone",
    artist: "Childish Gambino",
    src: Redbone,
    albumArt: RedboneCover,
  },
  { title: "One Love", artist: "Blue", src: OneLove, albumArt: OneLoveCover },
  {
    title: "Gangsta's Paradise",
    artist: "Coolio",
    src: Coolio,
    albumArt: CoolioCover,
  },
  {
    title: "Timezone",
    artist: "World Clock",
    src: Timezone,
    albumArt: TimezoneCover,
  },
  {
    title: "グッバイバイ",
    artist: "Hatsune Miku",
    src: グッバイバイ,
    albumArt: グッバイバイCover,
  },
];

const MusicPlayer = () => {
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef();

  const playNextSong = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex + 1) % songs.length);
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    }
  }, [currentSongIndex, isPlaying]);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSongEnd = () => {
    playNextSong();
  };

  const currentSong = songs[currentSongIndex];

  return (
    <div className="music-player">
      <LazyImage
        src={currentSong.albumArt}
        alt={currentSong.title}
        className="album-art"
      />
      <div className="music-player-right">
        <div className="song-info">
          <div className="now-playing">NOW PLAYING</div>
          <div className="song-title">{currentSong.title}</div>
          <div className="song-artist">{currentSong.artist}</div>
        </div>
        <div className="player-controls">
          <button onClick={handlePlayPause}>
            {isPlaying ? (
              <img src={pauseicon} alt="Pause" />
            ) : (
              <img src={playicon} alt="Play" />
            )}
            {isPlaying ? "Pause" : "Play"}
          </button>
          <button onClick={playNextSong}>
            <img src={nexticon} alt="Next" />
            Next
          </button>
        </div>
      </div>

      <audio
        ref={audioRef}
        key={currentSongIndex} // Adding key to help React identify each song should have its own audio element
        src={currentSong.src}
        onEnded={handleSongEnd}
        preload="none"
      />
    </div>
  );
};

export default MusicPlayer;
