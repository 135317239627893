import React, { useState, useEffect, useRef, useCallback } from "react";
import "./projectscommon.css";
import TabBar from "./TabBar";
import IFeel from "./IFeel";
import Goodreads from "./Goodreads";
import MedFolio from "./MedFolio";
import SagaOfShadows from "./SagaOfShadows";
import UTMCareerCentre from "./UTMCareerCentre";
import ComingSoon from "../../../ComingSoon/ComingSoon";

const projectComponents = {
  iFeel: IFeel,
  GoodReads: Goodreads,
  MedFolio: MedFolio,
  SagaOfShadows: SagaOfShadows,
  UTMCareerCentre: UTMCareerCentre,
};

const ProjectsCommon = ({ projectName }) => {
  const [activeTab, setActiveTab] = useState("");
  const [projectTabs, setProjectTabs] = useState([]);
  const [isClickAction, setIsClickAction] = useState(false); // New state to track click actions
  const contentRef = useRef(null);

  useEffect(() => {
    if (projectTabs.length > 0) {
      setActiveTab(projectTabs[0]);
    }
  }, [projectTabs]);

  const handleTabClick = (tab, event) => {
    event.stopPropagation();
    setIsClickAction(true); // Set flag to true when tab is clicked
    setActiveTab(tab);
    const section = document.getElementById(tab);
    section.scrollIntoView({ behavior: "smooth" });

    // Optional: Reset the click action flag after a delay
    setTimeout(() => {
      setIsClickAction(false);
    }, 1000); // Adjust delay as needed to match scroll duration
  };

  const handlePrototypeClick = (event) => {
    event.stopPropagation();
    console.log("PrototypeClick");
  };

  const handleScroll = useCallback(() => {
    if (isClickAction) return; // Ignore scroll events triggered by click actions

    const currentScrollPosition =
      contentRef.current.scrollTop + contentRef.current.offsetTop;
    let selectedTab = "";
    projectTabs.forEach((tab) => {
      const section = document.getElementById(tab);
      if (
        section.offsetTop <= currentScrollPosition &&
        section.offsetTop + section.offsetHeight > currentScrollPosition
      ) {
        selectedTab = tab;
      }
    });
    setActiveTab(selectedTab);
  }, [projectTabs, isClickAction]);

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (contentElement) {
        contentElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]); // Rerun when projectTabs or isClickAction changes

  const ProjectComponent =
    projectComponents[projectName] || (() => <ComingSoon />);

  const hideTabs = projectName === "SagaOfShadows";
  return (
    <div
      className="projects-container"
      ref={contentRef}
      onClick={handlePrototypeClick}
    >
      <TabBar
        activeTab={activeTab}
        onTabClick={handleTabClick}
        tabs={projectTabs}
        hideTabs={hideTabs}
      />
      <div className="content-wrapper">
        <ProjectComponent setProjectTabs={setProjectTabs} />
      </div>
    </div>
  );
};

export default ProjectsCommon;
