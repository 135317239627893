import React, { useState, useEffect } from "react";
import "./windowcommon.css";
import PropTypes from "prop-types";
import closeIcon from "../../assets/icons/close-icon.png";

const WindowTitleBar = ({ title, size, onClose, onDrag }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const onMouseMove = (e) => {
      if (isDragging) {
        const dx = e.clientX - startPosition.x;
        const dy = e.clientY - startPosition.y;
        onDrag(dx, dy);
        setStartPosition({
          x: e.clientX,
          y: e.clientY,
        });
      }
    };
    const onMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [isDragging, startPosition, onDrag]);

  const onMouseDown = (e) => {
    setIsDragging(true);
    setStartPosition({
      x: e.clientX,
      y: e.clientY,
    });
    e.preventDefault(); // Prevent text selection.
  };
  return (
    <div
      className="window-title-bar"
      style={{ ...size }}
      onMouseDown={onMouseDown}
    >
      <div className="window-buttons">
        <div className="window-button close" onClick={onClose}>
          <img src={closeIcon} alt="Close Button" />
        </div>
      </div>
      <div className="window-divider">
        <div className="window-divider-line"></div>
        <div className="window-divider-line"></div>
      </div>
      <div className="window-title">{title}</div>
    </div>
  );
};

WindowTitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onDrag: PropTypes.func.isRequired,
};

export default WindowTitleBar;
