import React, { useCallback, useState } from "react";
import { useWindowsManager } from "./hooks/useWindowsManager";

import BottomNav from "./components/BottomNav/BottomNav";
import Navbar from "./components/NavBar/Navbar";
import MusicPlayer from "./components/MusicPlayer/MusicPlayer";
import ClockWidget from "./components/ClockWidget/ClockWidget";
import WindowCommon from "./components/Window/WindowCommon";
import AboutMe from "./components/Window/AboutMeWindow/AboutMe";
import MyWorks from "./components/Window/MyWorksWindow/MyWorks";
import BootPage from "./components/BootPage/BootPage";
// import Instagram from "./components/Window/Instagram/Instagram2";
import NayTV from "./components/Window/NayTV/NayTV";
import Resume from "./components/Window/Resume/Resume";
import Calendar from "./components/Window/Calendar/Calendar";
import "./App.css";

//projects
import ProjectsCommon from "./components/Window/MyWorksWindow/Projects/ProjectsCommon";

function App() {
  const [booting, setBooting] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectTitle, setSelectedProjectTitle] = useState("");

  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const centerWindowOnScreen = (windowWidth, windowHeight) => {
    return {
      x: (screenWidth - windowWidth) / 2,
      y: (screenHeight - windowHeight) / 2,
    };
  };

  const initialWindowPosition = {
    aboutMe: { x: 200, y: 25 },
    myWorks: { x: 250, y: 70 },
    calendar: { x: 30, y: 30 },
    resume: centerWindowOnScreen(screenWidth - 250, screenHeight - 50),
    instagram: { x: 800, y: 40 },
    nayTV: { x: 450, y: 80 },
    projectsCommon: centerWindowOnScreen(screenWidth - 200, screenHeight - 50),
  };

  const {
    openWindows,
    zIndexMap,
    windowPosition,
    openWindow,
    closeWindow,
    bringWindowToFront,
  } = useWindowsManager(initialWindowPosition);

  const handleNavClick = (windowId) => {
    if (openWindows.includes(windowId)) {
      console.log("bringWindowToFront", windowId);
      bringWindowToFront(windowId);
    } else {
      console.log("openWindow", windowId);
      openWindow(windowId);
    }
  };

  const handleProjectSelect = useCallback(
    (projectWindowId, projectName) => {
      console.log("openWindow", projectWindowId);
      setSelectedProject(projectWindowId);
      setSelectedProjectTitle(projectName);
      openWindow("projectsCommon");
    },
    [openWindow]
  );

  const contentComponents = {
    aboutMe: <AboutMe />,
    myWorks: <MyWorks onProjectSelect={handleProjectSelect} />,
    calendar: <Calendar />,
    resume: <Resume />,
    // instagram: <Instagram />,
    nayTV: <NayTV />,
    projectsCommon: {
      content: <ProjectsCommon projectName={selectedProject} />,
      customTitle: selectedProjectTitle,
    },
  };

  const windowSizes = {
    aboutMe: {
      titleBar: { width: "700px", height: "30px" },
      body: { width: "700px", height: "400px" },
    },
    myWorks: {
      titleBar: { width: "750px", height: "30px" },
      body: { width: "750px", height: "350px" },
    },
    calendar: {
      titleBar: { width: "900px", height: "30px" },
      body: { width: "900px", height: "450px" },
    },
    resume: {
      titleBar: { width: "900px", height: "30px" },
      body: {
        width: "900px",
        height: screenHeight - 100 + "px",
      },
    },
    instagram: {
      titleBar: { width: "300px", height: "30px" },
      body: { width: "300px", height: "450px" },
    },
    nayTV: {
      titleBar: { width: "650px", height: "30px" },
      body: { width: "650px", height: "350px" },
    },
    projectsCommon: {
      titleBar: { width: screenWidth - 200 + "px", height: "30px" },
      body: {
        width: screenWidth - 200 + "px",
        height: screenHeight - 100 + "px",
      },
    },
  };

  return (
    <div className="App">
      <div className="mobile-only">
        <WindowCommon
          key="mobile"
          title="Mobile View"
          size={{
            titleBar: { width: "350px", height: "30px" },
            body: { width: "350px", height: "300px" },
          }}
          initialPosition={centerWindowOnScreen(350, 330)}
          content={
            <p className="mobile-text">
              This website is made for desktop view only. You'll know when you
              come back using your computer browser.
            </p>
          }
          windowId="mobile"
          isOpen={true}
        />
      </div>
      <div className="desktop-only">
        {booting ? (
          <BootPage onBootComplete={() => setBooting(false)} />
        ) : (
          <>
            <Navbar />
            <MusicPlayer />
            <div className="clock-widgets">
              <div className="clock_timezone">
                <div>
                  <ClockWidget timezoneOffset={5.5} />
                </div>
                <div>Pune</div>
              </div>
              <div className="clock_timezone">
                <div>
                  <ClockWidget timezoneOffset={4} />
                </div>
                <div>Dubai</div>
              </div>

              <div className="clock_timezone">
                <div>
                  <ClockWidget timezoneOffset={-5} />
                </div>
                <div>Toronto</div>
              </div>
            </div>
            {Object.entries(contentComponents).map(([windowId, content]) => (
              <WindowCommon
                key={windowId}
                title={
                  windowId.charAt(0).toUpperCase() +
                  windowId
                    .slice(1)
                    .replace(/([A-Z])/g, " $1")
                    .trim()
                }
                size={
                  windowSizes[windowId] || {
                    titleBar: { width: "600px", height: "30px" },
                    body: { width: "600px", height: "400px" },
                  }
                }
                initialPosition={windowPosition[windowId]}
                content={content.content || content}
                windowId={windowId}
                isOpen={openWindows.includes(windowId)}
                zIndex={zIndexMap[windowId] || 1}
                onClick={() => bringWindowToFront(windowId)}
                onClose={() => closeWindow(windowId)}
                customTitle={content.customTitle}
              />
            ))}
            <BottomNav onClick={handleNavClick} />
          </>
        )}
      </div>
    </div>
  );
}

export default App;
