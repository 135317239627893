import React from "react";
import useLazyLoadingImg from "../hooks/useLazyLoadingImg";

const LazyImage = ({ src, alt, ...props }) => {
  const { imageSrc, className } = useLazyLoadingImg(src);

  return (
    <img
      src={imageSrc}
      alt={alt}
      className={`lazy-loading-img ${className}`}
      loading="lazy"
      {...props}
    />
  );
};

export default LazyImage;
