import { useCallback, useState, useEffect } from "react";

export const useWindowsManager = (initialPosition) => {
  const [openWindows, setOpenWindows] = useState([]);
  const [zIndexMap, setZIndexMap] = useState({});
  const [windowPosition, setWindowPosition] = useState(initialPosition);

  const bringWindowToFront = useCallback((windowId) => {
    setOpenWindows((prev) => {
      if (prev.includes(windowId)) {
        return [...prev.filter((id) => id !== windowId), windowId];
      }
      return prev;
    });
  }, []);

  const openWindow = useCallback(
    (windowId) => {
      if (!openWindows.includes(windowId)) {
        setOpenWindows((prev) => [...prev, windowId]);
        setWindowPosition((prev) => ({
          ...prev,
          [windowId]: initialPosition[windowId],
        }));
      } else {
        // Call bringWindowToFront if the window is already open
        bringWindowToFront(windowId);
      }
    },
    [openWindows, bringWindowToFront, initialPosition]
  );

  const closeWindow = useCallback(
    (windowId) => {
      setOpenWindows((prev) => prev.filter((id) => id !== windowId));
      // Force update to reset position
      setWindowPosition((prev) => {
        const newPositions = { ...prev };
        newPositions[windowId] = { ...initialPosition[windowId] }; // Create a new object to ensure re-render
        return newPositions;
      });
    },
    [initialPosition]
  );

  useEffect(() => {
    const updatedZIndexMap = openWindows.reduce((acc, windowId, index) => {
      acc[windowId] = index + 1; // Incremental zIndex value
      return acc;
    }, {});
    setZIndexMap(updatedZIndexMap);
    console.log("zIndexMap", updatedZIndexMap);
  }, [openWindows]);

  return {
    openWindows,
    zIndexMap,
    windowPosition,
    openWindow,
    closeWindow,
    bringWindowToFront,
  };
};
