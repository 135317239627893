import React from "react";
import "./myworks.css";
import MyWorksCard from "./MyWorksCard";

// icons
import GraphicDesignIcon from "../../../assets/icons/graphic-design-icon.png";
import UIUXDesignIcon from "../../../assets/icons/uiux-design-icon.png";
import WebDevelopmentIcon from "../../../assets/icons/web-development-icon.png";
import ifeelIcon from "../../../assets/icons/ifeel-icon.png";
import medfolioIcon from "../../../assets/icons/medfolio-icon.png";
import thriftbidderIcon from "../../../assets/icons/thriftbidder-icon.png";
import careercenterIcon from "../../../assets/icons/careercenter-icon.png";
import sagaofshadowsIcon from "../../../assets/icons/sagaofshadows-icon.png";
import goodreadsIcon from "../../../assets/icons/goodreads-icon.png";
import Favsgoodreads from "../../../assets/icons/favs-goodreads-icon.png";
import Favsmedfolio from "../../../assets/icons/favs-medfolio-icon.png";
import Favsifeel from "../../../assets/icons/favs-ifeel-icon.png";
import DevIcon from "../../../assets/icons/dev-icon.png";

const MyWorks = ({ onProjectSelect }) => {
  const [currentCategory, setCurrentCategory] = React.useState("UI/UX Design");

  const projects = [
    {
      title: "UTM_CareerCentre.pdf",
      image: careercenterIcon,
      windowId: "UTMCareerCentre",
      category: "UI/UX Design",
    },
    {
      title: "MedFolio.ai",
      image: medfolioIcon,
      windowId: "MedFolio",
      category: "UI/UX Design",
    },
    {
      title: "Saga_of_Shadows__Spectral_Wolf.gam",
      image: sagaofshadowsIcon,
      windowId: "SagaOfShadows",
      category: "UI/UX Design",
    },

    {
      title: "GoodReads.pdf",
      image: goodreadsIcon,
      windowId: "GoodReads",
      category: "UI/UX Design",
    },
    {
      title: "iFeel@UofT.pdf",
      image: ifeelIcon,
      windowId: "iFeel",
      category: "UI/UX Design",
    },
    {
      title: "ThriftBidder.psd",
      image: thriftbidderIcon,
      windowId: "ThriftBidder",
      category: "UI/UX Design",
    },

    {
      title: "Emirates_Branding.psd",
      image: goodreadsIcon,
      windowId: "EmiratesBranding",
      category: "Graphic Design",
    },
    {
      title: "Expense_Tracker.js",
      image: DevIcon,
      category: "Web Development",
      url: "https://github.com/gojenaya/Expense-Tracker--React-Project",
    },
    {
      title: "TrailMapper.js",
      image: DevIcon,
      category: "Web Development",
      url: "https://github.com/gojenaya/TrailMapper_Application_JavaScript",
    },
    {
      title: "OnlineBanking.js",
      image: DevIcon,
      category: "Web Development",
      url: "https://github.com/gojenaya/Online-Banking-Website",
    },
  ];

  return (
    <div className="myworks-window">
      <div className="my-works-sidebar">
        <div className="my-works-sidebar-locations">
          <div className="my-works-sidebar-title">Locations</div>
          <ul>
            <li onClick={() => setCurrentCategory("Graphic Design")}>
              <img
                src={GraphicDesignIcon}
                alt="Graphic Design Icon"
                className="my-works-sidebar-icon"
              />
              Graphic Design
            </li>
            <li onClick={() => setCurrentCategory("UI/UX Design")}>
              <img
                src={UIUXDesignIcon}
                alt="UI/UX Design Icon"
                className="my-works-sidebar-icon"
              />
              UI/UX Design
            </li>
            <li onClick={() => setCurrentCategory("Web Development")}>
              <img
                src={WebDevelopmentIcon}
                alt="Web Development Icon"
                className="my-works-sidebar-icon"
              />
              Web Development
            </li>
          </ul>
        </div>
        <div className="my-works-sidebar-favourites">
          <div className="my-works-sidebar-title">Favourites</div>
          <ul>
            <li
              onClick={(event) => {
                event.stopPropagation();
                onProjectSelect("MedFolio", "MedFolio.ai");
              }}
            >
              <img
                src={Favsmedfolio}
                alt="Medfolio Icon"
                className="my-works-sidebar-icon"
              />
              MedFolio
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                onProjectSelect("GoodReads", "GoodReads.pdf");
              }}
            >
              <img
                src={Favsgoodreads}
                alt="GoodReads Icon"
                className="my-works-sidebar-icon"
              />
              GoodReads
            </li>
            <li
              onClick={(event) => {
                event.stopPropagation();
                onProjectSelect("iFeel", "iFeel@UofT.pdf");
              }}
            >
              <img
                src={Favsifeel}
                alt="iFeel @ UofT Icon"
                className="my-works-sidebar-icon"
              />
              iFeel@UofT
            </li>
          </ul>
        </div>
      </div>
      <div className="my-works-projects">
        {projects
          .filter((project) => project.category === currentCategory)
          .map((project) => (
            <MyWorksCard
              key={project.title}
              title={project.title}
              image={project.image}
              onClick={() =>
                !project.url
                  ? onProjectSelect(project.windowId, project.title)
                  : window.open(project.url)
              }
            />
          ))}
      </div>
    </div>
  );
};

export default MyWorks;
