import React, { useState, useEffect } from "react";
import "./windowcommon.css";
import WindowTitleBar from "./WindowTitleBar";
import WindowBody from "./WindowBody";
import PropTypes from "prop-types";

const WindowCommon = ({
  title,
  size,
  initialPosition,
  content,
  windowId,
  isOpen,
  zIndex,
  onClick,
  onClose,
  customTitle,
}) => {
  const [position, setPosition] = useState({
    x: initialPosition.x,
    y: initialPosition.y,
  });

  useEffect(() => {
    // Assuming WindowCommon manages its own internal position state
    setPosition(initialPosition);
  }, [initialPosition]);

  const onDrag = (dx, dy) => {
    setPosition((prevPosition) => {
      // Calculate the new position
      let newX = prevPosition.x + dx;
      let newY = prevPosition.y + dy;

      // Get viewport dimensions
      const viewportWidth = document.documentElement.clientWidth;
      const viewportHeight = document.documentElement.clientHeight;

      // Get window dimensions
      const windowWidth = parseInt(size.body.width.replace("px", ""), 10);
      const windowHeight = parseInt(size.body.height.replace("px", ""), 10);

      // Calculate the maximum x and y positions to keep the window inside the viewport
      const maxX = viewportWidth - windowWidth;
      const maxY = viewportHeight - windowHeight;

      // Constrain the new position to the viewport
      newX = Math.max(0, Math.min(newX, maxX));
      newY = Math.max(0, Math.min(newY, maxY));

      return { x: newX, y: newY };
    });
  };

  return (
    <div
      className={`window-common ${isOpen ? "open" : "close"}`}
      onClick={() => onClick(title, windowId)}
      style={{
        zIndex,
        transform: `translate(${position.x}px, ${position.y}px)`,
      }}
    >
      <WindowTitleBar
        title={customTitle || title}
        size={size.titleBar}
        onClose={() => onClose(windowId)}
        onDrag={onDrag}
      />
      {isOpen && <WindowBody size={size.body}>{content}</WindowBody>}
    </div>
  );
};

WindowCommon.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.shape({
    titleBar: PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
    }),
    body: PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
    }),
  }).isRequired,
  initialPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  content: PropTypes.node.isRequired,
  windowId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  customTitle: PropTypes.string,
};

export default WindowCommon;
