import { useState, useEffect } from "react";

const useLazyLoadingImg = (src) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setIsLoaded(true);
      setImageSrc(src);
    };
  }, [src]);

  return { imageSrc, className: isLoaded ? "" : "loading" };
};

export default useLazyLoadingImg;
