const bootMessages = [
  "COMPILING SOURCE CODE: COMPLETED",
  "CRACKING ENCRYPTION KEYS: CRACKED",
  "INITIALIZING SECURITY PROTOCOLS: WARNING, FIREWALL DISABLED",
  "DECRYPTING DATA PACKETS: CRACKED WITH WARNINGS",
  "OPENING PORTS FOR REMOTE ACCESS: PERMISSION GRANTED",
  "LINKING DATA NODES: WARNING, DANGEROUS CONTENT DETECTED",
  "SCANNING FOR MALWARE: SUSPICIOUS FILES QUARANTINED",
  "LOADING CHEESE DATABASE: GOUDA ENOUGH",
  "PREHEATING OVEN FOR COOKIE MONITORING: YUMMY",
  "ARMING NOODLE SLINGSHOT: AL DENTE",
  "LAUNCHING SUBMARINE TOASTER: WATERPROOF",
  "ADJUSTING GRAVITY SETTINGS: LIGHT AS A FEATHER",
  "SHUFFLING PLAYLIST FOR SPACE LOUNGE: GROOVY",
  "CALIBRATING MOOD RINGS: VIBRANT",
  "ENGAGING WARP DRIVE: STAR STREAKS AHEAD",
  "MIXING VIRTUAL PAINT PALETTE: COLORFUL",
  "BREEDING PLATYPUS DUCKLINGS: QUACK?",
  "PREPARING TIME MACHINE FOR COFFEE BREAKS: RETRO",
  "TRAINING NINJA TURTLES: SHELL-SHOCKED",
  "BUFFERING BUFFER OVERFLOW: OVERFLOWED",
  "ASSEMBLING TEAM OF SUPERHEROES: ASSEMBLED",
  "FLUFFING CLOUD STORAGE: CUMULONIMBUS",
  "CROSSING THE STREAMS: GHOSTBUSTERS ALERT",
  "FARMING PIXELS FOR RETRO GAMES: PIXELATED",
  "PEELING ONION LAYERS: TEARFUL",
  "TRAINING HOUSE PLANTS IN KARATE: LEAFY",
  "UNCORKING VINTAGE DIGITAL WINE: AGED",
  "TRANSMITTING MORSE CODE WITH FLASHLIGHTS: DASHED",
  "POLISHING CRYSTAL BALL FOR DATA PREDICTIONS: MYSTICAL",
  "ENCRYPTING SECRETS WITH MAGIC SPELLS: ABRA-CADABRA",
  "UNFOLDING LOOPS: ITERATION COMPLETED WITH EXTRA TWISTS",
  "BREEDING BUGS: MUTATIONS SUCCESSFUL, CHAOS ENSUES",
  "DECONSTRUCTING COMPILERS: MISSING SEMICOLONS FOUND",
  "SPLICING WIRES: SHOCKINGLY COMPLETED",
  "ENCRYPTING WITH ROT13: CIPHER TEXT SUSPICIOUSLY LEGIBLE",
  "RECURSING RECURSION: STACK OVERFLOW IMMINENT",
  "HASHING HASHBROWNS: TASTY CHECKSUMS GENERATED",
  "LINKING TIME TRAVEL PARADOXES: Y2K APPROVED",
  "TWEETING TO SERVERS: RESPONSES RECEIVED IN CHIRPS",
  "BROADCASTING WIFI WITH KILOBYTES: SIGNAL STRENGTHENED BY NOSTALGIA",
  "STIRRING JAVA BEANS: BREW INTERRUPTED BY GARBAGE COLLECTION",
  "CYCLING THROUGH POWER STATES: SLEEPY RESOURCES AWAKENED",
  "FLATTENING CSS: BOX MODELS RESISTED",
  "UNZIPPING FILES: COMPRESSION LOOSENED",
  "TELEPORTING PACKETS: SOME DATA MAY APPEAR DISTORTED",
  "COUNTING INFINITE LOOPS: COUNT REACHED INFINITY... TWICE",
  "FEEDING THE SERVER HAMSTER: ENERGY LEVELS REPLENISHED",
];

export default bootMessages;
