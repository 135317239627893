import React, { useState, useEffect } from "react";
import "./navbar.css";
import Logo from "../../assets/cropped-logo-ng.png";
import fullScreen from "../../assets/icons/full-screen-icon.png";


const toggleFullScreen = () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen().catch((e) => {
      console.error(`Failed to enter full-screen mode: ${e.message}`);
    });
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen().catch((e) => {
        console.error(`Failed to exit full-screen mode: ${e.message}`);
      });
    }
  }
};

const Navbar = () => {
  const [currentTime, setCurrentTime] = useState(getTime());

  // Update the time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(getTime());
    }, 1000); 

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const { day, month, date, year } = getDate();

  return (
    <div className = "top-nav">
      <div className = "nav-title">
        <img src={Logo} alt="Logo" />
        <h1>Nayanika Goje</h1>
      </div>
      <div className ="nav-right">
        <div className ="nav-date">{`${day}, ${month} ${date} ${year}`}</div>
        <div className ="nav-time">{currentTime}</div>
        <button onClick={toggleFullScreen} className="fullscreen-button">
          <img src={fullScreen} alt="Toggle Fullscreen" />
        </button>
      </div>
    </div>
  );
};

// Helper function to get the current date but with year 1999
const getDate = () => {
  const currentDate = new Date();
  let date = currentDate.getDate();
  if (date < 10) {
    date = '0' + date;
  }

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = months[currentDate.getMonth()];

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayOfWeek = daysOfWeek[currentDate.getDay()];

  return {
    date: date.toString(),
    month,
    day: dayOfWeek,
    year: 1999,
  };
};

// Helper function to get the current time
const getTime = () => {
  const currentDate = new Date();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  return formattedTime;
};

export default Navbar;
