import React, { useEffect } from "react";
import "./projectscommon.css";
import LazyImage from "../../../LazyImage";

import Overview from "../../../../assets/GoodReads/Overview.png";
import Briefing from "../../../../assets/GoodReads/Briefing.png";
import Defining from "../../../../assets/GoodReads/Defining.png";
import Developing from "../../../../assets/GoodReads/Developing.png";
import Discovering from "../../../../assets/GoodReads/Discovering.png";
import Reflection from "../../../../assets/GoodReads/Reflection.png";
const Tabs = [
  "Overview",
  "Briefing",
  "Discovering",
  "Defining",
  "Developing",
  "Reflection",
];

const Goodreads = ({ setProjectTabs }) => {
  useEffect(() => {
    setProjectTabs(Tabs);

    return () => setProjectTabs([]);
  }, [setProjectTabs]);

  return (
    <div className="project-body">
      <div id="Overview">
        <LazyImage src={Overview} alt="Overview" loading="lazy" />
        <iframe
          title="GoodReads Medium Fidelity Prototype"
          style={{
            width: "100%",
            height: "600px",
            border: "none",
            filter: "none",
            padding: "3%",
          }}
          loading="lazy"
          src="https://www.figma.com/embed?embed_host=share&hide_ui=1&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FOtnJxWzewVxHgVzKzxr78V%2FGroup-8-GoodReads--Mid-fidelity-(Copy)%3Fpage-id%3D0%253A1%26node-id%3D63-13549%26viewport%3D782%252C96%252C0.04%26t%3DrGvtHau1rv03AIsL-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26starting-point-node-id%3D63%253A11453"
        ></iframe>
      </div>
      <div id="Briefing">
        <LazyImage src={Briefing} alt="Briefing" loading="lazy" />
      </div>
      <div id="Discovering">
        <LazyImage src={Discovering} alt="Discovering" loading="lazy" />
      </div>
      <div id="Defining">
        <LazyImage src={Defining} alt="Defining" loading="lazy" />
      </div>

      <div id="Developing">
        <LazyImage src={Developing} alt="Developing" loading="lazy" />
      </div>
      <div id="Reflection">
        <LazyImage src={Reflection} alt="Reflection" loading="lazy" />
      </div>
    </div>
  );
};

export default Goodreads;
