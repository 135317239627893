import React, { useEffect } from "react";
import "./projectscommon.css";
import LazyImage from "../../../LazyImage";

import Overview from "../../../../assets/SagaOfShadows/Overview.jpg";
import PrototypeTitle from "../../../../assets/SagaOfShadows/PrototypeTitle.jpg";
import Prototype from "../../../../assets/SagaOfShadows/Prototype.png";
import Design from "../../../../assets/SagaOfShadows/Design.jpg";
import DesignLanguage from "../../../../assets/SagaOfShadows/DesignLanguage.jpg";
import End from "../../../../assets/SagaOfShadows/End.jpg";
const Tabs = [];

const SagaOfShadows = ({ setProjectTabs, hideTabs }) => {
  useEffect(() => {
    setProjectTabs(Tabs);

    return () => setProjectTabs([]);
  }, [setProjectTabs]);

  return (
    <div className="project-body" style={{ backgroundColor: "#1E2E36" }}>
      <LazyImage style={{ order: "1" }} src={Overview} alt="Overview" />
      <LazyImage
        style={{ order: "2" }}
        src={PrototypeTitle}
        alt="PrototypeTitle"
      />

      <iframe
        title="SagaOfShadows Prototype"
        style={{
          width: "100%",
          height: "600px",
          border: "none",
          backgroundImage: `url(${Prototype})`,
          backgroundWidth: "100%",
          backgroundSize: "cover",
          backgroundPosition: "left top",
          filter: "none",
          order: "3",
        }}
        src="https://www.figma.com/embed?embed_host=share&hide_ui=1&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FQdhTNFzUSt3BRElWAfzRjA%2FUX-Video-Game-A4%3Fpage-id%3D0%253A1%26node-id%3D9-44%26viewport%3D-1529%252C-794%252C0.44%26t%3DLSrUs7dKOWCQiLEU-1%26scaling%3Dscale-down%26content-scaling%3Dfixed%26starting-point-node-id%3D9%253A44"
      ></iframe>

      <LazyImage style={{ order: "4" }} src={Design} alt="Design" />
      <LazyImage
        style={{ order: "5" }}
        src={DesignLanguage}
        alt="DesignLanguage"
      />
      <LazyImage style={{ order: "6" }} src={End} alt="End" />
    </div>
  );
};

export default SagaOfShadows;
