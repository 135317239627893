import React, { useEffect } from "react";
import LazyImage from "../../../LazyImage";
import "./projectscommon.css";
import OverviewImg from "../../../../assets/iFeel/Overview.png";
import BriefingImg from "../../../../assets/iFeel/Briefing.png";
import DefiningImg from "../../../../assets/iFeel/Defining.png";
import DevelopingImg from "../../../../assets/iFeel/Developing.png";
import DiscoveringImg from "../../../../assets/iFeel/Discovering.png";
import FinalDesignImg from "../../../../assets/iFeel/FinalDesign.png";
import FinalHiFiImg from "../../../../assets/iFeel/FinalHiFi.png";
import Testing1Img from "../../../../assets/iFeel/Testing_1.png";
import Testing2Img from "../../../../assets/iFeel/Testing_2.png";
import ReflectionImg from "../../../../assets/iFeel/Reflection.png";

const Tabs = [
  "Overview",
  "Briefing",
  "Discovering",
  "Defining",
  "Developing",
  "Testing",
  "Final Hi-Fi",
  "Reflection",
];

const IFeel = ({ setProjectTabs }) => {
  useEffect(() => {
    // Pass the tabs up to the parent component when IFeel mounts
    setProjectTabs(Tabs);

    // Clean up: Reset the tabs when IFeel unmounts
    return () => setProjectTabs([]);
  }, [setProjectTabs]);

  return (
    <div className="project-body">
      <div id="Overview">
        <LazyImage src={OverviewImg} alt="Overview" />
        <LazyImage src={FinalDesignImg} alt="Final Design" />
      </div>
      <div id="Briefing">
        <LazyImage src={BriefingImg} alt="Briefing" />
      </div>
      <div id="Discovering">
        <LazyImage src={DiscoveringImg} alt="Discovering" />
      </div>
      <div id="Defining">
        <LazyImage src={DefiningImg} alt="Defining" />
      </div>
      <div id="Developing">
        <LazyImage src={DevelopingImg} alt="Developing" />
      </div>
      <div id="Testing">
        <LazyImage src={Testing1Img} alt="Lean Evaluation" />
        <iframe
          title="iFeel Medium Fidelity Prototype"
          style={{
            width: "100%",
            height: "600px",
            border: "none",
            filter: "none",
            padding: "3%",
          }}
          src="https://www.figma.com/embed?embed_host=share&hide_ui=1&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F82VUg2fnGnUVsQPps4YtK5%2FStoryboard%3Fnode-id%3D29-517%26page-id%3D29%253A510%26scaling%3Dscale-down%26starting-point-node-id%3D29%253A517%26viewport%3D-1639%252C613%252C0.26%26t%3DPvSeme4V0MpCFHpZ-1"
        ></iframe>
        <LazyImage src={Testing2Img} alt="Usability Testing Insights" />
      </div>
      <div id="Final Hi-Fi">
        <LazyImage src={FinalHiFiImg} alt="Final HiFi Designs" />
      </div>
      <div id="Reflection">
        <LazyImage src={ReflectionImg} alt="Reflection" />
      </div>
    </div>
  );
};

export default IFeel;
