import React from "react";
import "./aboutme.css";
import NayImg from "../../../assets/It'sNAY.JPG";
import useLazyLoadingImage from "../../../hooks/useLazyLoadingImg";

const AboutMe = () => {
  const { imageSrc, className } = useLazyLoadingImage(NayImg);
  return (
    <div className="about-me ">
      <img
        src={imageSrc}
        alt="It's NAY"
        className={`about-me-image lazy-loading-img ${className}`}
      />
      <div className="about-me-text">
        <p className="about-me-text-intro">
          Yo!! I'm Nayanika, the vibe curator of &nbsp; <br></br>
          <span className="about-me-text-NAY">NAY OS.</span> <br></br> Get ready
          to warp into the rad world where pixels party like it's 1999!{" "}
        </p>
        <p className="about-me-text-paragraph">
          Now, let me break it down for you. NAY OS is like your digital time
          machine, but instead of boring stuff, it's packed with neon lights,
          glitchy graphics, and a vibe that's just pure nostalgia mixed with a
          modern twist.
        </p>
        <p className="about-me-text-paragraph">
          As the one steering this digital system, my gig is more than just
          design. I'm here to make your online experience as cool as a retro
          music video. UI/UX, Front-End tricks, Graphic Design – it's all in the
          mix. But, hold up – I'm not all about screens and codes. When I'm not
          in the digital groove, catch me on the court shooting hoops, solving
          mysteries like a detective, and dabbling in art like a boss.
        </p>
      </div>
    </div>
  );
};

export default AboutMe;
