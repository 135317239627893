import React from "react";
import "./bottomnav.css";
import PropTypes from "prop-types";

const NavItem = ({ iconSrc, altText, label, onClick, windowId }) => (
  <div className="nav-item" onClick={onClick}>
    <img src={iconSrc} alt={altText} />
    <h1>{label}</h1>
  </div>
);

NavItem.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  windowId: PropTypes.string,
};

export default NavItem;
