import React, { useEffect } from "react";
import "./projectscommon.css";
import LazyImage from "../../../LazyImage";

import Overview from "../../../../assets/UTMCareerCentre/Overview.jpg";
import GeneralResponsibilities from "../../../../assets/UTMCareerCentre/GeneralResponsibilties.jpg";
import HowWeWorked from "../../../../assets/UTMCareerCentre/HowWeWorked.jpg";
import TheNumbers from "../../../../assets/UTMCareerCentre/TheNumbers.jpg";
import TheFunStuff from "../../../../assets/UTMCareerCentre/TheFunStuff.jpg";
import LessonsLearned from "../../../../assets/UTMCareerCentre/LessonsLearned.jpg";

const Tabs = [
  "Overview",
  "Responsibilities",
  "How We Worked",
  "The Numbers",
  "The Fun Stuff",
  "Lessons Learned",
];

const UTMCareerCentre = ({ setProjectTabs }) => {
  useEffect(() => {
    setProjectTabs(Tabs);

    return () => setProjectTabs([]);
  }, [setProjectTabs]);

  return (
    <div className="project-body">
      <div id="Overview">
        <LazyImage src={Overview} alt="Overview" />
      </div>
      <div id="Responsibilities">
        <LazyImage
          src={GeneralResponsibilities}
          alt="General Responsibilities"
        />
      </div>
      <div id="How We Worked">
        <LazyImage src={HowWeWorked} alt="How We Worked" />
      </div>
      <div id="The Numbers">
        <LazyImage src={TheNumbers} alt="The Numbers" />
      </div>
      <div id="The Fun Stuff">
        <LazyImage src={TheFunStuff} alt="The Fun Stuff" />
      </div>
      <div id="Lessons Learned">
        <LazyImage src={LessonsLearned} alt="Lessons Learned" />
      </div>
    </div>
  );
};

export default UTMCareerCentre;
