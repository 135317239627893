import React, { Component } from "react";
import "./clockwidget.css";

export default class ClockWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date(),
    };
  }

  componentDidMount() {
    this.timerId = setInterval(() => {
      this.setState({
        time: new Date(),
      });
    }, 1000);
  }

  componentWillMount() {
    clearInterval(this.timerId);
  }

  getTimeInTimeZone(time, offset) {
    const utc = time.getTime() + time.getTimezoneOffset() * 60000;
    return new Date(utc + 3600000 * offset);
  }

  render() {
    const { timezoneOffset = 0 } = this.props;
    const timeZoneTime = this.getTimeInTimeZone(
      this.state.time,
      timezoneOffset
    );

    const hoursRotation =
      (timeZoneTime.getHours() % 12) * 30 + timeZoneTime.getMinutes() * 0.5;
    const minutesRotation = timeZoneTime.getMinutes() * 6;

    return (
      <div className="clock">
        <div
          className="hour_hand"
          style={{
            transform: `rotateZ(${hoursRotation}deg)`,
          }}
        />
        <div
          className="min_hand"
          style={{
            transform: `rotateZ(${minutesRotation}deg)`,
          }}
        />

        <span className="twelve">12</span>
        <span className="one">•</span>
        <span className="two">•</span>
        <span className="three">3</span>
        <span className="four">•</span>
        <span className="five">•</span>
        <span className="six">6</span>
        <span className="seven">•</span>
        <span className="eight">•</span>
        <span className="nine">9</span>
        <span className="ten">•</span>
        <span className="eleven">•</span>
      </div>
    );
  }
}
