import React from "react";
import "./comingsoon.css";
import gifRef from "../../assets/comingsoon.gif";

const ComingSoon = () => {
  return (
    <div class="coming-soon-container">
      <div class="gif-references">
        <img src={gifRef} alt="Coming Soon" />
      </div>
    </div>
  );
};

export default ComingSoon;
