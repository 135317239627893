import React from "react";
import "./calendar.css";
import LazyImage from "../../LazyImage";
import MyJourney from "../../../assets/MyJourney.png";
// Helper function to calculate the grid placement based on month and year

const Calendar = () => {
  return (
    <div className="timeline-calendar">
      <LazyImage src={MyJourney} alt="My Journey Timeline" />
    </div>
  );
};

export default Calendar;
