// Resume.js
import React from "react";
import "./resume.css";
import LazyImage from "../../LazyImage";
import DownloadIcon from "../../../assets/icons/download-icon.png";
import ResumeImage from "../../../assets/Nayanika_Goje_Resume.jpg";
import ResumeUrl from "../../../assets/Nayanika_Goje_Resume.pdf";

const Resume = () => {
  return (
    <div className="resume-window resume-img-container">
      <div className="resume-img-container">
        <a href={ResumeUrl} download className="resume-download-icon">
          <img src={DownloadIcon} alt="Download Resume" />
        </a>
        <LazyImage
          src={ResumeImage}
          className="resume-img"
          title="Resume"
          alt="Resume"
        />
      </div>
    </div>
  );
};

export default Resume;
